
export const emailRules = [
  v => !!v || 'E-mail is required',
  v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
]

export const codeRules = [
  v => !!v || 'Confiramtion code is required',
  v => v && v.toString().length === 6 || 'Confirmation code must be 6 digits'
]

export const passwordRules = [
  v => !!v || 'Password is required',
  v => v && v.length > 5 || 'Password must be at least 6 characters' 
]

