<template>
  <v-app>
    <div class="reset-password">
      <v-container>

        <v-row>
          <v-col>
            <div class="circle-logo text-center pt-12">
              <logo-img></logo-img>
            </div>
            <div class="logo-img">
              <div class="text-center">
                <logo class="logo" />
                <div class="caption primary--text">
                  Manager
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row
          align-content="center"
        >
          <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <div class="wrapper mx-auto">
              <div class="text-center">
                <h1 class="h1">
                  Reset Password
                </h1>
                <p>
                  We will send the reset password instructions to your email.
                </p>
              </div>

              <v-form
                @submit.prevent="send"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  type="email"
                  label="Type your email"
                  v-model="email"
                  :rules="emailRules"
                  :error-messages="error"
                  outlined
                >
                </v-text-field>

                <v-btn
                  type="submit"
                  block
                  color="primary"
                  :loading="loading"
                  :disabled="loading || !valid"
                >
                  Send
                </v-btn>

              </v-form>

              <p class="overline mt-6">
                Already have an account?
                <router-link :to="{name: 'Login'}">Sign In</router-link>
                <br>
                Don't have account?
                <router-link :to="{name: 'Register'}">Create Account</router-link>
                 <br>
                <router-link :to="{name: 'ResendConfirmationInstructions'}">
                  Didn't receive confirmation instructions?
                </router-link>
              </p>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import Logo from '@/components/Logo'
import LogoImg from '@/components/LogoImg'
import { requestPasswordReset } from '@/services/http/passwords'
import { emailRules } from '@/services/validators'

export default {
  name: 'RequestResetPassword',
  components: {
    Logo, LogoImg
  },
  data() {
    return {
      loading: false,
      error: null,
      valid: true,
      email: '',
      emailRules
    }
  },
  methods: {
    async send() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true
          const { id } = await requestPasswordReset(this.email)
          this.$router.push({name: 'ResetPassword', params: { id }})
        }
      } catch(e) {
        console.error(e)
        this.error = e.toString()
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    email(v) {
      if (v) this.error = null
    }
  }
}
</script>
