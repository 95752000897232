<template>
  <div class="logo-padd" :style="style">
    <img :src="require('@/assets/adopisoft-logo-white.png')" />
  </div>
</template>

<script>
  export default {
    name: 'LogoImg',
    props: {
      padding: {
        type: Number,
        default: 15,
      },
      size: {
        type: Number,
        default: 200,
      },
      shape: {
        type: String,
        default: 'circle',
        validator: shape => {
          return ['circle', 'square'].includes(shape)
        }
      }
    },
    computed: {
      style() {
        const isDark = this.$vuetify.theme.isDark
        const colors = this.$vuetify.theme.themes[isDark ? 'dark' : 'light']
        return {
          backgroundColor: colors.primary,
          padding: this.padding + 'px',
          width: this.size + 'px',
          height: this.size + 'px',
          borderRadius: this.shape == 'circle' ? '100%' : (this.size / 8) + 'px'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo-padd {
    display: inline-block;

    img {
      width: 100%;
    }
  }
</style>

