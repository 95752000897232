import http from "@/services/http/http";
import { APP_TOKEN } from "@/services/constants";
import validation_errors from "@/services/http/validation_errors";
import catch_http_error from "@/services/http/catch_http_error";

export async function requestPasswordReset(email) {
  try {
    const url = "/api/v1/passwords";
    const res = await http.post(url, { email, token: APP_TOKEN });
    return res.data;
  } catch (e) {
    e.response = e.response || {};
    const status = e.response.status;
    if (status === 404) 
      return Promise.reject("Email not found");
    else throw e;
  }
}

export async function pendingResetPassword(id) {
  const url = "/api/v1/passwords/" + id;
  const res = await http.get(url);
  return res.data;
}

export async function resetPassword(id, code, password, password_confirmation) {
  try {
    const url = "/api/v1/passwords/" + id;
    const res = await http.put(url, { code, password, password_confirmation });
    return res.data;
  } catch (e) {
    e.response = e.response || {};
    if (e.response.status === 422) return Promise.reject(validation_errors(e));
    else catch_http_error(e);
  }
}
